import React, { useEffect } from "react";
import CardImg from "../Assets/phone_with_location.png";
import CardImgPh from "../Assets/download-asset-ph.png";
import AppStore from "../Assets/app_store_white.png";
import PlayStore from "../Assets/google_play_white.png";
import AppStoreAr from "../Assets/app_store_white_ar.png";
import PlayStoreAr from "../Assets/google_play_white_ar.png";
import AOS from 'aos';
import "aos/dist/aos.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const Download = () => {
  const { t, i18n } = useTranslation();
  const appStore = i18n.language === "en" ? AppStore : AppStoreAr;
  const playStore = i18n.language === "en" ? PlayStore : PlayStoreAr;
  useEffect(() => {
    AOS.init();
  }, [])
  const comingSoon = () => {
    toast.info('App coming soon!', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return (
    <section className="download-container">
      <div className="download-card glass">
        <div className="download-section-left">
          <div className="download-img">
            <img className="iphone web" src={CardImg} alt="" />
            <img className="iphone mobile" src={CardImgPh} alt="" />
          </div>
        </div>
        <div
          className="download-section-right"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div
            className="card-heading"
            style={{ color: "#005645", fontSize: "35px", fontWeight: "600" }}
          >
            {t("download_title")}
          </div>
          <p className="card-text">{t("download_desc")}</p>
          <div className="footer-button-section download-button" style={{display:"flex"}}>
            <img
              src={appStore}
              alt=""
              className="store-button"
              onClick={comingSoon}
            />
            <img
              src={playStore}
              alt=""
              className="store-button store-btn-apple"
              onClick={comingSoon}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;